import React, { FC } from 'react'

import Modal from 'containers/Modal'
import { setAnalyticsProps } from 'helpers/metaTags'

import FormMistake from './FormMistake'

const MistakeModal: FC<{ selectedText: string; onClose: () => void }> = ({
  selectedText,
  onClose
}) => (
  // @ts-ignore
  <Modal onClose={onClose}
    id='mistakeForm'
    title='Нашли ошибку в тексте?'>
    <div
      {...setAnalyticsProps({
        type: 'action',
        group: 'notification',
        action: 'textError',
        pos: 'view'
      })}
    >
      {/* @ts-ignore */}
      <FormMistake selectedText={selectedText} />
    </div>
  </Modal>
)

export default MistakeModal
