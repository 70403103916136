// @ts-nocheck
import React, { FC, FormEventHandler } from 'react'

import { compose, pathOr } from 'ramda'
import { connect } from 'react-redux'
import { inject } from 'react-tunnel-16'
import { Field, reduxForm } from 'redux-form'

import Link from 'components/Link'
import {
  currentContractorGuidSelector,
  currentContractorNameSelector,
  getUserEmail
} from 'redux/modules/personal'
import Button from 'UI/Button'
import Text from 'UI/Text'
import { FieldTextarea } from 'UI/Textarea'

import s from './FormMistake.scss'
import submit from '../submit'

interface IFormMistake {
  path: string
  userMail: string
  selectedText: string
  contractorName: string
  contractorGUID: string
  clientApi: object
  handleSubmit: FormEventHandler<HTMLFormElement>

  invalid: boolean
  submitting?: boolean
}

const FormMistake: FC<IFormMistake> = ({
  path,
  userMail,
  selectedText,
  contractorName,
  contractorGUID,
  clientApi,
  handleSubmit,
  invalid,

  submitting
}) => (
  <form
    className={s.form}
    onSubmit={handleSubmit(
      submit({
        formName: 'mistake',
        contractor_name: contractorName,
        contractor_code: contractorGUID,
        url: path,
        user_mail: userMail,
        selected_text: selectedText,
        clientApi
      })
    )}
  >
    <fieldset className={s.fieldset}>
      <Text semibold>Выделенный фрагмент</Text>
      <Text>{selectedText}</Text>
    </fieldset>

    <fieldset className={s.fieldset}>
      <Text semibold>Комментарий</Text>
      <Field
        component={FieldTextarea}
        name='comment'
        placeholder='Введите сообщение'
      />
    </fieldset>

    <Text>
      Отправляя форму я даю
      <Link to='/documents/consent'
        color='blue'>
        &nbsp;согласие на обработку персональных данных&nbsp;
      </Link>
      в соответствии с
      <Link to='/documents/policy'
        color='blue'>
        &nbsp;политикой конфиденциальности&nbsp;
      </Link>
      .
    </Text>
    <Button type='submit'
      disabled={submitting || invalid}>
      Отправить
    </Button>
  </form>
)

export default compose(
  reduxForm({ form: 'mistakeForm' }),
  connect(({ personal, router }) => ({
    path: pathOr({}, ['location', 'pathname'])(router),
    contractorGuid: currentContractorGuidSelector(personal),
    contractorName: currentContractorNameSelector(personal),
    userMail: getUserEmail(personal)
  })),
  inject(({ helpers: { clientApi } }) => ({ clientApi }))
)(FormMistake)
