/* eslint-disable complexity */
import React, { PureComponent } from 'react'

import cx from 'classnames'
import { bool, func, number, string, object, array } from 'prop-types'
import {
  prop,
  path,
  pathOr,
  propOr,
  pick,
  composeP,
  keys,
  slice,
  not,
  equals,
  isEmpty
} from 'ramda'
import { Link as LinkScroll } from 'react-scroll'

import HonestSign from 'components/HonestSign'
import Image from 'components/Image'
import ImageLens from 'components/ImageLens'
import Link from 'components/Link'
import PopupImage from 'components/PopupImage'
import PropertyTable from 'components/Product/PropertyTable'
import ProductActions from 'components/ProductActions'
import ProductCode from 'components/ProductCode'
import ProductGalleryModal from 'components/ProductGalleryModal'
import CapOne from 'components/ProductPage/CapOne'
import ProductOneActions from 'components/ProductPage/ProductOneActions'
import ProductPhoto from 'components/ProductPage/ProductPhoto'
import ProductTabs from 'components/ProductPage/ProductTabs'
import ProductPrices from 'components/ProductPrices'
import ReviewButton from 'components/ReviewButton'
import ButtonMoreOptions from 'containers/ButtonMoreOptions'
import DisableBodyScroll from 'containers/DisableBodyScroll'
import Labels from 'containers/Labels'
import Modal from 'containers/Modal'
import basket from 'decorators/basket'
import { display } from 'decorators/device'
import product from 'decorators/product'
import { preparePropertySelector } from 'redux/modules/products'
import { activate } from 'redux/modules/tabs'
import Spacing from 'UI/Spacing'

import ProductModifiers from './ProductModifiers'
import styles from './ProductOne.scss'

const SEARCH_LINE_HEIGHT = -59
const PROPERTIES_COUNT = 8
const MAIN_IMAGE_SIZE = 472
const LOYALTY_IMAGE_SIZE = 347
const FIXED_OFFSET = 284
const THRESHOLD_SCROLL_TO_FIXED = 300
const DIVIDE_BY_TWO = 2

@basket
@product
@display
export default class ProductOne extends PureComponent {
  static propTypes = {
    pageTabsName: string.isRequired,
    item: object,
    multipleBuy: object,
    location: object,
    galleryItems: object,
    gallerySettings: object,
    galleryModalSettings: object,
    gallerySpriteData: object,
    descriptionStores: object,
    isUpdating: object,
    dispatch: func,
    hideModal: func,
    removeFromFavorite: func,
    removeFromCompare: func,
    showModal: func,
    onNavigateTo: func,
    onChangeInBasket: func,
    onChangeOrder: func,
    setActiveStore: func,
    fetchSpritePhoto: func,
    setForPoints: func,
    setAnalogsModal: func,
    analogs: array,
    expendables: array,
    isUserWithoutPassword: bool,
    inCompare: bool,
    inFavorite: bool,
    isLoyalty: bool,
    isBookmark: bool,
    isProductLoaded: bool,
    isQuickView: bool,
    isMultiple: bool,
    isNotMultiple: bool,
    isBuyNotMultiple: bool,
    forPoints: bool,
    isForPoints: bool,
    isForPointsBasket: bool,
    isDesktop: bool,
    amount: number,
    bookmark: number,
    delay: number,
    min: number,
    remain: number,
    step: number,
    minInit: number,
    stepInit: number,
    basket: number,
    id: string,
    tabsName: string,
    groupType: string,
    activeStore: string,
    basketStore: string
  }

  static defaultProps = {
    galleryItems: {},
    location: {},
    gallerySettings: {},
    galleryModalSettings: {},
    gallerySpriteData: {},
    dispatch: () => {},
    hideModal: () => {},
    showModal: () => {},
    setAnalogsModal: () => {},
    onNavigateTo: () => {},
    onChangeInBasket: () => {},
    onChangeOrder: () => {},
    removeFromFavorite: () => {},
    removeFromCompare: () => {},
    fetchSpritePhoto: () => {},
    setActiveStore: () => {},
    setForPoints: () => {},
    analogs: [],
    expendables: [],
    isUserWithoutPassword: true,
    isMultiple: true,
    isNotMultiple: false,
    isBuyNotMultiple: false,
    inCompare: false,
    inFavorite: false,
    isBookmark: false,
    isLoyalty: false,
    isQuickView: false,
    isProductLoaded: false,
    forPoints: false,
    isForPoints: false,
    isForPointsBasket: false,
    tabsName: String(new Date().valueOf())
  }

  state = {
    mainPicture: prop('DETAIL_PICTURE_PATH', this.props.item),
    isImagePopup: false,
    isGalleryPopup: false,
    isFixed: false
  }

  componentDidMount() {
    const { location, isDesktop } = this.props

    if (location.search && location.search.indexOf('scroll') !== -1) {
      setTimeout(() => {
        this.scrollToPropsLink.scrollTo('tabsProductPage', {
          offset: SEARCH_LINE_HEIGHT,
          smooth: true
        })
        this.handleScrollToProperty()
      }, 0)
    }

    if (isDesktop) {
      window.addEventListener('scroll', this.handleScroll)
    }

  }

  componentDidUpdate(prevProps) {
    if (not(equals(this.props.item.ID, prevProps.item.ID))) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        mainPicture: prop('DETAIL_PICTURE_PATH', this.props.item)
      })
    }
  }

  componentWillUnmount() {
    const { isDesktop } = this.props
    if (isDesktop) {
      window.removeEventListener('scroll', this.handleScroll)
    }
  }

  handleScroll = () => {
    const { isFixed } = this.state
    const scrollTop = window.scrollY
    const threshold = THRESHOLD_SCROLL_TO_FIXED

    if (scrollTop > threshold && !isFixed) {
      this.setState({ isFixed: true })
    } else if (scrollTop <= threshold && isFixed) {
      this.setState({ isFixed: false })
    }
  }

  handleShowAnalogs = id => e => {
    const MIDDLE_BUTTON = 1
    if (e.button === MIDDLE_BUTTON) return
    this.props.setAnalogsModal(id)
  }

  handleHidePopup = popup => () => {
    this.setState({
      [popup]: false
    })
  }

  handleHideModal = () => {
    const { hideModal } = this.props
    hideModal()
  }

  handleHideGallery = () => {
    const { isQuickView } = this.props
    this.handleHidePopup('isGalleryPopup')()
    if (isQuickView) {
      this.handleHideModal()
    }
  }

  handleScrollToProperty = () => {
    const { dispatch, pageTabsName } = this.props

    dispatch(
      activate({
        tab: 'params',
        name: pageTabsName
      })
    )
  }

  handleSetMainPicture = ({ path: pathPicture }) =>
    this.setState({
      mainPicture: pathPicture
    })

  handleClickClose = href => () => {
    const { onNavigateTo, hideModal } = this.props
    return composeP(
      () => hideModal(),
      () => onNavigateTo(href)
    )()
  }

  handleSetStore = store => this.props.setActiveStore({ store })

  handleShowGallery = () => this.setState({ isGalleryPopup: true })

  onShowGalleryItems = ({ path: itemPath }) => {
    this.handleSetMainPicture({ path: itemPath })
    this.handleShowGallery()
  }

  onFetchSpritePhoto = () => {
    const { id, fetchSpritePhoto, galleryItems, gallerySpriteData } = this.props
    if (isEmpty(gallerySpriteData)) {
      const spriteLink = propOr('', 'sprite', galleryItems)
      fetchSpritePhoto({ id, url: spriteLink })
    }
  }

  renderProperty({ title, propertyName, href, onClick }) {
    const { item, isQuickView, hideModal, location } = this.props
    const property = propOr({}, propertyName, item)
    const propertyValue = prop('VALUE', property)
    const currentPathName = propOr('', 'pathname', location)
    if (!propertyValue) {
      return null
    }
    let itemPropName
    switch (title) {
      case 'Бренд':
        itemPropName = 'brand'
        break
      case 'Серия':
        itemPropName = 'category'
        break
      case 'Страна':
        itemPropName = 'manufacturer'
        break
      case 'Лицензия':
        itemPropName = 'license'
        break
      default:
    }
    return (
      <span className={styles.property}
        itemProp={itemPropName}>
        {title}
        &nbsp;
        {!isQuickView && href && (
          <Link to={href}
            color='blue'>
            {propertyValue}
          </Link>
        )}
        {isQuickView && href && href !== currentPathName && (
          <Link onClick={this.handleClickClose}
            to={href}
            color='blue'>
            {propertyValue}
          </Link>
        )}
        {isQuickView && href && href === currentPathName && (
          <Link onClick={hideModal}
            href={href}>
            {propertyValue}
          </Link>
        )}
        {!href && (
          <span
            role='presentation'
            className={styles.linkBlock}
            onClick={onClick}
            itemProp='potentialAction'
          >
            {propertyValue}
          </span>
        )}
      </span>
    )
  }

  renderActions() {
    const {
      id,
      isUserWithoutPassword,
      item,
      multipleBuy,
      isLoyalty,
      isQuickView,
      isMultiple,
      isNotMultiple,
      isBuyNotMultiple,
      isBookmark,
      bookmark,
      activeStore,
      forPoints,
      isForPoints,
      isForPointsBasket,
      setForPoints
    } = this.props

    const analogsInfo = pick(['ACTIVE', 'ISSET_ANALOGS'], item)
    const withActions = !isUserWithoutPassword && item.PRICE !== undefined
    const outOfAssortment =
      !isUserWithoutPassword && !propOr(true, 'ACTIVE', item)

    if (withActions || outOfAssortment) {
      return isLoyalty ? (
        <ProductOneActions id={id}
          mode='one'
          isRightBlock />
      ) : (
        <>
          <div className={styles.orderBlock}>
            <ProductPrices
              className={styles.prices}
              item={item}
              multipleBuy={multipleBuy}
              isLoyalty={false}
              isUserWithoutPassword={isUserWithoutPassword}
              onShowAnalogs={this.handleShowAnalogs}
              isMultiple={isMultiple}
              isNotMultiple={isNotMultiple}
              isBuyNotMultiple={isBuyNotMultiple}
              activeStore={activeStore}
              forPoints={forPoints}
              isForPoints={isForPoints}
              setForPoints={setForPoints}
              amount={this.props.basket || this.props.amount}
            />

            <div className={styles.actions}>
              <ProductActions
                productId={id}
                inCompare={this.props.inCompare}
                inFavorite={this.props.inFavorite}
                delay={this.props.delay}
                analogsInfo={analogsInfo}
                onShowAnalogs={this.handleShowAnalogs}
                onUpdateGroup={this.props.onChangeInBasket}
                onChange={this.props.onChangeOrder}
                isMultiple={isMultiple}
                isNotMultiple={isNotMultiple}
                isBuyNotMultiple={isBuyNotMultiple}
                isForPoints={isForPoints}
                isForPointsBasket={isForPointsBasket}
                isBookmark={isBookmark}
                bookmark={bookmark}
                groupType='catalog'
                type={this.props.groupType}
                removeFromCompare={this.props.removeFromCompare}
                removeFromFavorite={this.props.removeFromFavorite}
                isProductCard
                onSetStore={this.handleSetStore}
                activeStore={activeStore}
                basketStore={this.props.basketStore}
                item={this.props.item}
                min={this.props.min}
                remain={this.props.remain}
                step={this.props.step}
                minInit={this.props.minInit}
                stepInit={this.props.stepInit}
                amount={this.props.amount}
                basket={this.props.basket}
                isUpdating={this.props.isUpdating}
                descriptionStores={this.props.descriptionStores}
              />
            </div>
          </div>
          {!isQuickView &&
            <Spacing margin='top'
              size='tiny'>
              <ReviewButton product={item}/>
            </Spacing>
          }
        </>
      )
    }

    return (
      <>
        {!isQuickView && (
          <CapOne
            showModal={this.props.showModal}
            mode='ProductOne'
            price={parseFloat(pathOr(0, ['PRICE'], item))}
            multiplicity={pathOr(false, ['MULTIPLICITY', 'HTML'], item)}
            isStoreAvailable={pathOr(false, ['STORE', 'AVAILABLE'], item)}
          />
        )}
        {isQuickView && (
          <CapOne
            showModal={this.props.showModal}
            mode='ProductOneQuick'
            price={parseFloat(pathOr(0, ['PRICE'], item))}
            multiplicity={pathOr(false, ['MULTIPLICITY', 'HTML'], item)}
            hideModal={this.props.hideModal}
            isStoreAvailable={pathOr(false, ['STORE', 'AVAILABLE'], item)}
          />
        )}
      </>
    )
  }

  renderActionsBlock() {
    const {
      isQuickView,
      isLoyalty,
      item,
      analogs,
      expendables,
      isUserWithoutPassword,
      isBookmark,
      bookmark,
      tabsName,
      isProductLoaded,
      id,
      galleryItems,
      gallerySpriteData,
      galleryModalSettings
    } = this.props
    const { isGalleryPopup, mainPicture, isFixed } = this.state

    const centerOffset = (window.innerWidth / DIVIDE_BY_TWO) + FIXED_OFFSET

    return (
      <div
        className={cx(styles.actionsBlock, {
          [styles.actionsBlockQuickView]: isQuickView,
          [styles.actionsBlockLoyalty]: isLoyalty,
          [styles.actionsBlockFixed]: isFixed
        })}
        style={isFixed && !isQuickView? { left: `${centerOffset}px`} : {}}
      >
        {this.renderActions()}
        {isQuickView && (
          <ProductTabs
            id={propOr('', 'ID', item)}
            item={item}
            analogs={analogs}
            expendables={expendables}
            isUserWithoutPassword={isUserWithoutPassword}
            isQuickView={isQuickView}
            isLoyalty={isLoyalty}
            isBookmark={isBookmark}
            bookmark={bookmark}
            tabsName={tabsName}
            isProductLoaded={isProductLoaded}
          />
        )}

        {isGalleryPopup && (
          <ProductGalleryModal
            id={id}
            mainPicture={mainPicture}
            galleryItems={galleryItems}
            spriteData={gallerySpriteData}
            gallerySettings={galleryModalSettings}
            videoItem={propOr('', 'PRODUCT_VIDEO', item)}
            onChange={this.handleSetMainPicture}
            onClose={this.handleHideGallery}
            handleFetchSpritePhoto={this.onFetchSpritePhoto}
          />
        )}
      </div>
    )
  }

  render() {
    if (!this.props.item || keys(this.props.item).length === 0) {
      return (
        <div
          style={{
            height: 900
          }}
        />
      )
    }

    const {
      id,
      item,
      isUserWithoutPassword,
      isLoyalty,
      isQuickView,
      isBookmark,
      bookmark,
      activeStore,
      galleryItems,
      gallerySettings,
      isDesktop
    } = this.props
    const { mainPicture } = this.state
    const barcode = prop('BARCODE', item)
    const article = path(['ARTICLE'], item)
    const brandId = path(['TM', 'ID'], item)
    const brandLogo = path(['TM', 'LOGO'], item)
    const rulerId = path(['RULER', 'ID'], item)
    const licenseId = path(['LICENSE', 'ID'], item)
    const countryId = path(['COUNTRY', 'ID'], item)
    const isHonestSign = pathOr(false, ['IS_HONEST_SIGN'], item)
    const brandFilterLink = brandId ? `/catalog/brand/${brandId}` : null
    const rulerFilterLink = rulerId ? `/catalog/ruler/${rulerId}` : null
    const licenseFilterLink = licenseId ? `/catalog/license/${licenseId}` : null
    const countryFilterLink = countryId ? `/catalog/country/${countryId}` : null
    const productSection = Number(path(['SECTION', 'ID'], item))
    const outOfAssortment =
      !isUserWithoutPassword && !propOr(true, 'ACTIVE', item)
    const isGrayPreview = isUserWithoutPassword ? false : outOfAssortment
    const properties = preparePropertySelector(item)
    const mainImageSize = isLoyalty ? LOYALTY_IMAGE_SIZE : MAIN_IMAGE_SIZE
    return (
      <div
        className={cx(styles.productOne, {
          [styles.productOneCompact]: isQuickView,
          [styles.productOneLoyalty]: isLoyalty
        })}
      >
        <div className={styles.cardWrapper}>

          <div
            className={cx(styles.artGroup, {
              [styles.artGroupQuickView]: isQuickView
            })}
          >
            <div className={styles.codeWrapper}>
              <ProductCode
                name='Код'
                type='code'
                code={prop('CODE_1C', item)}
                isLoyalty={isLoyalty}
              />
              {article && (
                <ProductCode
                  name='Артикул'
                  type='vendor'
                  code={article}
                  isLoyalty={isLoyalty}
                />
              )}
              {barcode && barcode !== 'not' && barcode !== 'Not' && (
                <ProductCode
                  name='Штрихкод'
                  type='barcode'
                  code={prop('BARCODE', item)}
                  isLoyalty={isLoyalty}
                />
              )}
            </div>
            <div className={styles.groupProperty}
              itemScope
              itemType='Brand'>
              {this.renderProperty({
                title: 'Бренд',
                propertyName: 'TM',
                href: isLoyalty ? null : brandFilterLink
              })}
              {this.renderProperty({
                title: 'Серия',
                propertyName: 'RULER',
                href: isLoyalty ? null : rulerFilterLink
              })}
              {this.renderProperty({
                title: 'Лицензия',
                propertyName: 'LICENSE',
                href: isLoyalty ? null : licenseFilterLink
              })}
              {this.renderProperty({
                title: 'Страна',
                propertyName: 'COUNTRY',
                href: isLoyalty ? null : countryFilterLink
              })}
            </div>
          </div>
          <div
            className={cx(styles.productOneTable, {
              [styles.productOneTableQuickView]: isQuickView
            })}
          >
            <div
              className={cx(styles.imageWrapper, {
                [styles.imageWrapperQuickView]: isQuickView,
                [styles.imageWrapperLoyalty]: isLoyalty
              })}
            >
              <div
                className={cx(styles.productImages, {
                  [styles.productImagesGrayscale]: isGrayPreview,
                  [styles.productImagesColumn]: isLoyalty && !isQuickView
                })}
              >
                <div
                  className={cx(styles.productsOneWrapImage, {
                    [styles.productsOneWrapImageMain]:
                      !isLoyalty && !isQuickView,
                    [styles.productsOneWrapImageQuickView]: isQuickView
                  })}
                  itemProp='image'
                >
                  {!isUserWithoutPassword && (
                    <ButtonMoreOptions
                      inFavorite={this.props.inFavorite}
                      inCompare={this.props.inCompare}
                      isBookmark={isBookmark}
                      bookmark={bookmark}
                      activeStore={activeStore}
                      onUpdateGroup={this.props.onChangeInBasket}
                      basket={this.props.basket}
                    />
                  )}
                  {!isLoyalty && (
                    <div
                      className={cx(styles.labels, {
                        [styles.labels_auth]: !isUserWithoutPassword,
                        [styles.labelsQuickView]: isQuickView
                      })}
                    >
                      <Labels type='one'
                        item={item} />
                    </div>
                  )}
                  <div
                    className={styles.image}
                    role='presentation'
                    onClick={this.handleShowGallery}
                  >
                    {isQuickView || !isDesktop ? (
                      <>
                        <Image
                          backgroundSize='fullWidth'
                          width={600}
                          height={600}
                          src={mainPicture}
                          isLazy={false}
                        />
                        {isHonestSign && isDesktop && (
                          <HonestSign className={styles.honestSign} />
                        )}
                      </>
                    ) : (
                      <ImageLens
                        width={mainImageSize}
                        height={mainImageSize}
                        zoomWidth={563}
                        src={mainPicture}
                        isLazy={false}
                      />
                    )}
                  </div>
                </div>
                {propOr(false, 'isShowGallery', gallerySettings) && (
                  <ProductPhoto
                    selectedPath={mainPicture}
                    items={galleryItems}
                    settings={gallerySettings}
                    isLoyalty={isLoyalty}
                    onChange={this.handleSetMainPicture}
                    isQuickView={isQuickView}
                    handleShowGallery={this.onShowGalleryItems}
                    handleFetchSpritePhoto={this.onFetchSpritePhoto}
                  />
                )}
                <Modal id='productVideo'>
                  <div
                    className={styles.modalVideo}
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{
                      __html: path(['PRODUCT_VIDEO'], item)
                    }}
                  />
                </Modal>
                {this.state.isImagePopup && (
                  <DisableBodyScroll isNested={isQuickView}>
                    <PopupImage
                      src={mainPicture}
                      onClose={this.handleHidePopup}
                    />
                  </DisableBodyScroll>
                )}
              </div>
            </div>
            <div className={styles.blockInfo}>
              <div className={styles.blockInfoTop}>
                {!isQuickView && (
                  <div
                    className={cx(styles.infoTd, {
                      [styles.infoTdLoyalty]: isLoyalty
                    })}
                  >
                    <div className={styles.propertyBlock}>
                      <div className={styles.propertyTitle}>
                        Характеристики товара
                      </div>
                      <PropertyTable
                        productSection={productSection}
                        properties={slice(0, PROPERTIES_COUNT, properties)}
                        id='myPropertyTable'
                        mode='ProductOne'
                        isLoyalty={isLoyalty}
                        isBookmark={isBookmark}
                        bookmark={bookmark}
                      />
                    </div>
                    <Spacing
                      margin='top'
                      size='small'
                    >
                      <LinkScroll
                        className={cx(styles.linkScroll)}
                        to='description'
                        smooth
                      >
                        Смотреть всё
                      </LinkScroll>
                    </Spacing>

                    <Spacing margin='top'>
                      {isHonestSign && <HonestSign />}
                    </Spacing>
                    {false && brandLogo && (
                      <div className={styles.propertyBrand}
                        itemProp='brand'>
                        <Image
                          backgroundSize='contain'
                          imgClassName={styles.propertyBrandLogo}
                          src={brandLogo}
                          itemProp='logo'
                        />
                        {!isLoyalty && (
                          <Link
                            to={brandFilterLink}
                            itemProp='url'
                          >
                            Все товары
                            <span className={styles.propertyBrandName}>
                              {pathOr('', ['TM', 'VALUE'], item)}
                            </span>
                          </Link>
                        )}
                      </div>
                    )}
                  </div>
                )}
                {
                  this.renderActionsBlock()
                }
              </div>
              {!isQuickView && (
                <ProductModifiers productId={id} />
              )}
            </div>

          </div>
        </div>
      </div>
    )
  }
}
