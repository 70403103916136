import { pathOr } from 'ramda'
import { Action } from 'redux-act'
import { SubmissionError } from 'redux-form'

import { setFeedback } from 'redux/modules/invoices'
import { delayHideModal, showModal } from 'redux/modules/modal'

import { IFormShipment } from './FormShipment/FormShipment'

type FormNames = 'feedback_sb' | 'callback' | 'shipment_feedback' | 'mistake'
type SubmitProps = {
  formName: FormNames
  popupSuccess: string
  popupError: string
  clientApi: {
    post: (str: string, reqParams: {}) => Promise<object>
  }
}

const TIMEOUT = 3000
// пост запрос с данными формы
const submit =
  ({
    formName,
    clientApi,
    popupSuccess = 'resultSuccess',
    popupError = 'resultError',
    ...params
  }: SubmitProps & IFormShipment) =>
    (values: any, dispatch: (arg0: Action<null, null>) => void) => {
      return clientApi
        .post(`/v3/form/send/${formName}/`, {
          params: {
            ...values,
            ...params
          }
        })
        .then((res: object) => {
          const success = pathOr(false, ['data', 'response', 'SUCCESS'], res)
          if (!success) {
            const errors = pathOr({}, ['data', 'errors'], res)
            throw new SubmissionError({ ...errors })
          }
          if (success) {
            // @ts-ignore
            dispatch(setFeedback({
              key: params.shipmentGuid,
              value: true
            }))
          }
          // @ts-ignore
          dispatch(showModal(popupSuccess))
        })
        .catch(() => {
        // @ts-ignore
          dispatch(showModal(popupError))
        })
        .finally(() => {
        // @ts-ignore
          dispatch(delayHideModal(TIMEOUT))
        })
    }

export default submit
