import CallBackButtonBase from './FormCallBack'
import FormHOC from './FormHOC'
import MistakeFormBase from './FormMistake'
import SecurityFormButtonBase from './FormSecurity'
import ShipmentButtonBase from './FormShipment'

const SecurityFormButton = FormHOC(SecurityFormButtonBase)
const CallBackButton = FormHOC(CallBackButtonBase)
const ShipmentButton = FormHOC(ShipmentButtonBase)
const MistakeForm = FormHOC(MistakeFormBase)

export { SecurityFormButton, CallBackButton, ShipmentButton, MistakeForm }